<template>
  <div>
    <b-card bg-variant="light" header="Energía Eléctrica" header-bg-variant="primary" header-text-variant="white" align="center" >
        
        <b-form @submit="modalEnviar" @reset="cancelar" align="left" id="formularioResiduos">
            <b-form-row>
                <b-col cols="12" md="4">
                    <b-form-group
                        id="group-red_energia"
                        label="Tiene energía de red pública"
                        label-for="red_energia"
                    >
                        <b-form-select 
                            id="red_energia" 
                            v-model="form.red_energia" 
                            class="mb-3" 
                            required 
                            :disabled="verDetalles?true:false"
                        >
                            <b-form-select-option value="0">No</b-form-select-option>
                            <b-form-select-option value="1">Si</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="4">
                    <b-form-group
                        id="group-uso_energia"
                        label="Uso de energía"
                        label-for="uso_energia"
                    >
                        <b-form-select 
                            id="uso_energia" 
                            v-model="form.uso_energia" 
                            class="mb-3" 
                            required 
                            :disabled="verDetalles?true:false"
                        >
                            <b-form-select-option value="0">No</b-form-select-option>
                            <b-form-select-option value="1">Si</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="4">
                    <b-form-group
                        id="group-genera_propia_energia"
                        label="Genera su propia energía"
                        label-for="genera_propia_energia"
                    >
                        <b-form-select 
                            id="genera_propia_energia" 
                            v-model="form.genera_propia_energia" 
                            class="mb-3" 
                            required 
                            :disabled="verDetalles?true:false"
                        >
                            <b-form-select-option value="0">No</b-form-select-option>
                            <b-form-select-option value="1">Si</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="4">
                    <b-form-group
                        id="group-capacidad_energia"
                        label="Capacidad de generación de energía"
                        label-for="capacidad_energia"
                    >
                        <b-form-input
                            id="capacidad_energia"
                            v-model="form.capacidad_energia"
                            type="number"
                            min="0"
                            max="10000"
                            required
                            :disabled="verDetalles?true:false"
                        ></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="4">
                    <b-form-group
                        id="group-con_combustible_gener"
                        label="Tipo de consumo combustible"
                        label-for="con_combustible_gener"
                    >
                        <b-form-select 
                            id="con_combustible_gener" 
                            v-model="form.con_combustible_gener" 
                            class="mb-3" 
                            required 
                            :disabled="verDetalles?true:false"
                        >
                            <b-form-select-option value="0">Diesel</b-form-select-option>
                            <b-form-select-option value="1">Otros</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="4">
                    <b-form-group
                        id="group-cant_combustible_gener"
                        label="Cantidad de combustible generado"
                        label-for="cant_combustible_gener"
                    >
                        <b-form-input
                            id="cant_combustible_gener"
                            v-model="form.cant_combustible_gener"
                            type="number"
                            min="0"
                            max="10000"
                            required
                            :disabled="verDetalles?true:false"
                        ></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col cols="12" class="text-right">
                    <b-button type="submit" variant="primary" class="mr-2" :disabled="verDetalles?true:false">
                        Guardar
                        </b-button>
                    <b-button type="reset" >Cancelar</b-button>
                </b-col>
            </b-form-row>

        </b-form>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {encontrarError} from '../../../contantesErrores';
  export default {
      name: 'EnergiaElectricaVigilanciaControl',
    data() {
      return {
        editar:false,
        form: {
            id: null,
            red_energia: null,
            uso_energia: null,
            genera_propia_energia: null,
            capacidad_energia: null,
            con_combustible_gener: null,
            cant_combustible_gener: null
        },
      }
    },
    async mounted(){
        if(this.visitaEnGestion.id){
            this.form.id=this.visitaEnGestion.id
            await this.cargarEnergiaElectrica(this.visitaEnGestion.id)
        }
    },
    computed:{
        ...mapState('gestionarVisitas', ['verDetalles','visitaEnGestion']),
        ...mapState('gestionarVisitasVigilanciaControl', ['resultEnergiaElectrica', 'energiaElectricaCargada' ]),
    },
    methods: {
        ...mapActions(['activarLoading']),
        ...mapActions('gestionarVisitasVigilanciaControl', ['crearEnergiaElectrica', 'cargarEnergiaElectricaById', 'editarEnergiaElectrica']),

        async cargarEnergiaElectrica(id){
            this.activarLoading(true)
            try{
                await this.cargarEnergiaElectricaById(id)
                if(this.energiaElectricaCargada.length>0){
                    this.editar=true
                    this.form=this.energiaElectricaCargada[0]
                }
                this.activarLoading(false)
            }catch(e){
                encontrarError(this, e.data)
            }
        },

        modalEnviar(event){
            event.preventDefault()
             this.$bvModal.msgBoxConfirm('Se van a guardar los datos de Energía Eléctrica, ¿Desea continuar?.', {
                title: 'Por favor confirme',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'primary',
                okTitle: 'Continuar',
                cancelTitle: 'Cancelar',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
                })
                .then(value => {
                    if(value){
                        this.onSubmit()
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },

        async onSubmit() {
            this.activarLoading(true)

            let dataGuardar = {
                energia: this.form
            }

             try{
                 if(this.editar){
                     await this.editarEnergiaElectrica(dataGuardar)
                 }else{
                     await this.crearEnergiaElectrica(dataGuardar)
                 }
                if(this.resultEnergiaElectrica.data.status==200){
                    this.activarLoading(false)
                    this.modalOk()
                }
            }catch(e){
                this.activarLoading(false)
                encontrarError(this, e.data)
            }
        },

        modalOk(){
            this.$bvModal.msgBoxOk('Los Datos fueron guardados correctamente.', {
                title: 'Confirmación',
                size: 'md',
                buttonSize: 'md',
                okVariant: 'success',
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
                centered: true
                })
                .then(() => {
                    // this.$router.push({name: 'gestionVisita'})
                    this.cargarEnergiaElectrica(this.visitaEnGestion.id)
                })
                .catch(err => {
                    console.log(err)
                })
        },
        
        cancelar(){
            this.$bvModal.msgBoxOk('Se perderán los datos sin guardar.', {
                title: 'Cancelar',
                size: 'md',
                buttonSize: 'md',
                okVariant: 'success',
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
                centered: true
                })
                .then(() => {
                    this.$router.push({name: 'gestionarVisitaVigilanciaControl'})
                })
                .catch(err => {
                    console.log(err)
                })
        }, 
        
    }
  }
</script>

<style scoped>
    
</style>